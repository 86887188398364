<script setup lang="ts">
import { type PropType, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { type Project } from '~/types/project.type';
import ProjectCard from './partials/project-card.vue';
import ContactModal from './partials/contact-modal.vue';
import { useMetaStore } from '~/stores/meta.store';
import helper from '~/resources/js/libraries/helper';
import MenuHeader from './partials/header-menu.vue';
import { useRuntimeConfig } from '#app';

const props = defineProps<{
  data: { [key: string]: any };
}>();

const config = useRuntimeConfig()

const isEmailFormOpen = ref(false);

const router = useRouter();

const hasLocation = computed(() => {
	return props.data.location_on_map
		&& props.data.location_on_map.lng
		&& props.data.location_on_map.lat
});
const hasGallery = computed(() => {
	return props.data.gallery_pictures
		&& props.data.gallery_pictures.length;
});

const sections = ref([
	{
		id: 'highlights',
		show: props.data.highlight,
		titleKey: 'client.projects_highlights',
		highlight: true,
	},
	{
		id: 'overview',
		show: props.data.overview,
		titleKey: 'client.projects_overview',
	},
	{
		id: 'payment-plan',
		show: props.data.payment_plan,
		titleKey: 'client.projects_payment_plan',
	},
	{
		id: 'floor-plans',
		show: props.data.floor_plans,
		titleKey: 'client.projects_floor_plans',
	},
	{
		id: 'photo-gallery',
		show: hasGallery,
		titleKey: 'client.projects_photo_gallery',
	},
	{
		id: 'location-map',
		show: hasLocation,
		titleKey: 'client.projects_location_map',
	},
	{
		id: 'pdf-brochure',
		show: props.data.brochure,
		titleKey: 'client.projects_pdf_brochure',
	},
	{
		id: 'project-units',
		show: props.data.project_units.length,
		titleKey: 'client.projects_project-units'
	}
]);

const metaStore = useMetaStore();
metaStore.setMeta(props.data.meta);
const currency = helper.cookies.currency();

const scrollTo = (id: string) => {
	sections.value.forEach(section => section.highlight = false);
	const section = sections.value.find(section => section.id === id);
	if (section) {
		section.highlight = true;
	}
	router.push({ hash: `#${id}` });
}

const handleImageUrl = (imageUrl: string) => {
	return [new URL(`${imageUrl}?w=800&h=600&fit=cover`, config.public.mediaUrl as string).href];
}
</script>

<template>
	<LayoutAppHead></LayoutAppHead>

	<NuxtLayout name="desktop-projects">
		<template #menus>
			<menu-header :country_id="data.country_id || data.data.country.country_id || data.data.country._id"></menu-header>
		</template>

		<main>
			<section>

				<!-- Project Hero -->
				<div class="relative mx-auto mb-2">
					<img v-if="data.picture" class="w-full object-cover object-top" :src="`${data.picture}?w=1728&650`" :alt="data.name" style="height: 650px;">
					<div class="absolute inset-0 bg-gradient-to-t from-black to-rgb(49 46 129 / 0.9) opacity-90 rounded-md">
					</div>
					<div class="relative container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8">
						<div class=" absolute bottom-0 p-5 min-w-full">
							<div class="flex justify-between w-full items-center">
								<div>
									<h2 class="text-white text-3xl font-bold mb-2">
										{{ data.name }}
									</h2>
									<span class="text-zinc-300 text-base font-normal mb-3 block">
										By {{ data.developer_name }}
									</span>
									<p class="text-neutral-100 text-base font-normal"> <i class="fa-solid fa-location-dot text-neutral-100"></i>
										{{ data.city_name }}, {{ data.country_name }}
									</p>
								</div>
								<div class="ms-auto">
									<small class="text-zinc-300 text-base mb-2">{{ $t('client.starting_from') }}</small>
									<h2 class=" text-white text-2xl font-bold mb-2">
										{{ currency.symbol }}
										{{ new Intl.NumberFormat().format(data.start_price) }}
									</h2>
									<button type="button" @click="isEmailFormOpen = true" class="uppercase rounded-md bg-indigo-900 px-7 py-4 text-sm  text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
										{{ $t('client.make_an_enquiry') }}
									</button>
								</div>
							</div>
						</div>

					</div>
				</div>

				<!-- Jump Links -->
				<div class="border-b py-3 bg-neutral-100 flex justify-center gap-10 sticky top-0 z-10">
					<button v-for="section in sections.filter(section => section.show)" :key="section.id" @click="scrollTo(section.id)" class="text-xl font-bold" :class="section.highlight ? 'text-indigo-900' : 'text-zinc-600'">
						{{ $t(section.titleKey) }}
					</button>
				</div>

				<!-- Project Sections -->
				<div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mt-3 mb-8">
					<div class="w-4/5 mx-auto mt-8">
						<section ref="highlights" id="highlights" v-if="data.highlight" class="mb-8">
							<h3 class="text-indigo-900 text-2xl font-bold mb-5">{{ $t('client.projects_highlights') }}</h3>
							<div v-html="data.highlight"></div>
						</section>
						<section ref="overview" id="overview" v-if="data.overview" class="mb-8">
							<h3 class="text-indigo-900 text-2xl font-bold mb-5">{{ $t('client.projects_overview') }}</h3>
							<div v-html="data.overview"></div>
						</section>
						<section ref="payment-plan" id="payment-plan" v-if="data.payment_plan" class="mb-8">
							<h3 class="text-indigo-900 text-2xl font-bold mb-5">{{ $t('client.projects_payment_plan') }}</h3>
							<div v-html="data.payment_plan"></div>
						</section>
						<section ref="floor-plans" id="floor-plans" v-if="data.floor_plans" class="mb-8">
							<h3 class="text-indigo-900 text-2xl font-bold mb-5">{{ $t('client.projects_floor_plans') }}</h3>
							<div v-html="data.floor_plans"></div>
						</section>
						<section ref="photo-gallery" id="photo-gallery" v-if="hasGallery" class="mb-8">
							<h3 class="text-indigo-900 text-2xl font-bold mb-5">{{ $t('client.projects_photo_gallery') }}</h3>
							<lightbox :images="data.gallery_pictures" displayStyle="carousel" />
						</section>
						<section ref="location-map" id="location-map" v-if="hasLocation" class="mb-8">
							<h3 class="text-indigo-900 text-2xl font-bold mb-5">{{ $t('client.projects_location_map') }}</h3>
							<shared-map-i-frame :lng="data.location_on_map.lng" :lat="data.location_on_map.lat" addMark />
						</section>
						<section ref="pdf-brochure" id="pdf-brochure" v-if="data.brochure" class="mb-8">
							<h3 class="text-indigo-900 text-2xl font-bold mb-5">{{ $t('client.projects_pdf_brochure') }}</h3>
							<a :href="data.brochure" target="_blank">
								<button type="button" class="uppercase rounded-md bg-indigo-900 px-7 py-4 text-sm text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
									<i class="fa-solid fa-file-pdf fa-lg me-2"></i>
									{{ $t('client.projects_download_pdf_brochure') }}
								</button>
							</a>
						</section>
						<section ref="project-units" id="project-units" v-if="data.project_units.length" class="mb-3">
							<h3 class="text-indigo-900 text-2xl font-bold mb-5">{{ $t('client.projects_project-units') }}</h3>
							<div class="col-span-2 mt-12" v-for="project_unit in data.project_units">

								<div class="border-2 bg-white rounded-lg p-4">
									<div class="flex gap-3 items-center justify-between">
										<div v-if="project_unit.gallery_pictures?.length" class="grid grid-flow-row border-r px-3 last:border-0">
											<lightbox 
												:images="project_unit.gallery_pictures" 
												displayStyle="carousel-compact" 
												style="cursor: pointer;" 
												arrows
											/>
										</div>
										<div v-else class="grid grid-flow-row border-r px-3 last:border-0">
											<img class="object-cover w-48 h-48 rounded-lg" src="~/assets/img/unit_placeholder.jpg" /> 
										</div>
										
										<div class="flex flex-col">
											<div>
											<p class="text-gray-700">{{ $t('client.category') }}: <span class="font-bold text-gray-900"> {{
												project_unit.category_name }}</span></p>
											<p class="text-gray-700">{{ $t('client.unit_code') }}: <span class="font-bold text-gray-900"> {{
												project_unit.unit_code }}</span></p>
											<p class="text-gray-700">{{ $t('client.units_count') }}: <span class="font-bold text-gray-900"> {{
												project_unit.units_count }}</span> </p>

										</div>
										<div class="flex text-center my-3 gap-3" v-if="project_unit.features.base_features">
										<div class="grid grid-flow-row border-r px-3 last:border-0" v-for="feature in project_unit.features.base_features" :key="feature.slug">
											<template v-if="feature.input_type == 'image'">
													<lightbox :images="handleImageUrl(feature.value)" displayStyle="singleImage" />
													<div class="text-xs text-gray-500 mt-3">{{ feature.name }}</div>
											</template>
											<template v-else>
												<div class="flex flex-col justify-center items-center">
													<span class="mb-3 w-14 h-14 flex justify-center items-center bg-gray-200 rounded-full">
														<i :class="`${feature.icon} fa-xl text-gray-400`"></i>
													</span>
													<span class="text-xs text-gray-500">
														{{ feature.value }}
														{{ feature.input_type === 'number' ? feature.name : '' }}
													</span>
												</div>
											</template>
										</div>
										</div>
									</div>
										<p class="font-bold text-2xl text-indigo-900 ms-auto">
											{{ helper.formatPrice(project_unit.price) }}
											{{ currency.symbol }}
										</p>

									</div>
									
									<div class="p-3 text-sm text-gray-600 border-t" v-if="project_unit.features.multi_features?.filter((f: any) => f.input_type != 'image')?.length">
										<div class="flex flex-col mb-3" v-for="feature in project_unit.features.multi_features.filter((f: any) => f.input_type != 'image')">
											<h5 class="font-medium text-indigo-800 mb-3 text-xl">{{ feature.name }}</h5>
											<div class="grid grid-cols-4 gap-3">
												<div class="flex items-center" v-for="sub_feature in feature.value">
													<i :class="sub_feature.icon"></i>
													<span class="text-gray-600 text-sm ms-1">{{ sub_feature.value }}</span>
												</div>
											</div>
										</div>
									</div>
									<div class="flex flex-wrap gap-2" v-if="project_unit.features.multi_features?.filter((f: any) => f.input_type == 'image')?.length">
										<div class="flex flex-col gap-2 mb-1" v-for="feature in project_unit.features.multi_features.filter((f: any) => f.input_type == 'image')">
											<h5 class="font-medium text-indigo-800 mb-1">{{ feature.name }}</h5>
											<img class="w-44 h-36 rounded-md object-cover" :src="feature.value" itemprop="thumbnailUrl" />
										</div>
									</div>
								</div>
							</div>

						</section>
					</div>
				</div>
			</section>

			<!-- Contact Us Form -->
			<div class="relative mx-auto" style="height: 40rem;">
				<img class="w-full object-cover" :src="`${data.background}?w=1728&h=650&fit=cover`" :alt="data.name" style="height: 40rem;">
				<div class="absolute inset-0 bg-gradient-to-b from-slate-800 to-slate-500 opacity-50 rounded-md"></div>
				<div class="absolute bottom-0 p-5 flex flex-col justify-center items-center w-full">
					<h2 class="text-white text-3xl font-medium tracking-wide mb-3">
						{{ $t('client.contact_us') }}
					</h2>
					<layout-email-form />
				</div>
			</div>

			<ContactModal v-model="isEmailFormOpen" />
		</main>

	</NuxtLayout>
</template>
